import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import RSelect from 'react-select';
import { useStore } from 'stores/stores';
import Loader from 'theme/Loader';
import Modal from 'theme/Modal';
import Radio from 'theme/Radio';
import TranslationContext from 'translations';
import './OptIn.scss';
import { requestOptIn, requestOptOut } from './requests.js';

const MATCH_STATUS_OPTIONS = [
  {
    value: 'in',
    label: (
      <>
        <span>Yes, please match me.</span>
      </>
    ),
  },
  {
    value: 'out',
    label: (
      <>
        <span>No, thank you. I do not need a new match.</span>
      </>
    ),
  },
];

const MatchStatus = ({ partner, activeConversationId, activelyMatchedPeerCount }) => {
  const history = useHistory();
  const t = useContext(TranslationContext);
  const { userStore, loading: userLoading } = useStore();
  const { user } = userStore;

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null); //['in', 'out']
  const [reason, setReason] = useState(undefined);

  const reasons = useMemo(() => {
    return t.getSplitted('settings.pause.reasons').map((r) => ({ label: r, value: r }));
  }, [t]);

  const saveChanges = useCallback(() => {
    if (!response) return;

    setLoading(true);
    const optOutReq = activelyMatchedPeerCount > 1
      ? requestOptOut({ memberSlug: user.slug, reason, endConversations: true, activeConversationId })
      : requestOptOut({ memberSlug: user.slug, reason, endConversations: true});
    const req = response === 'in' ? requestOptIn({ memberSlug: user.slug, reason, endConversations: true }) : optOutReq;

    req
      .then(() =>
        setTimeout(() => {
          userStore.getUser().then(() => {
            setLoading(false);
            history.push('/?unmatched=true');
          });
        }, 1000)
      )
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [user, response, reason]);

  const close = useCallback(() => {
    history.push('/');
  }, []);

  return (
    <Modal isOpen close={close} className="MatchStatus">
      <div>
        <Loader loading={loading || userLoading} color="white" />
        <h1>Unmatch me from my partner</h1>
        {partner && (
          <p className="p-new">
            Unmatching from {partner.firstName} means that we will cancel your remaining conversations.{' '}
            {partner.firstName} will be notified immediately via email.
          </p>
        )}
        <div className="p-new">
          <h2>Select reason</h2>
          <RSelect
            inputId="match_status_reason"
            className="react-select MatchStatus__select"
            classNamePrefix="MatchStatus__select react-select"
            options={reasons}
            onChange={(o) => setReason(o.value)}
            value={reasons.find((o) => o.value == reason)}
            placeholder="Please select"
            autoBlur
          />
        </div>
        <fieldset className="p-new">
          <legend>
            <h2>Would you like to be matched with a new partner?</h2>
          </legend>
          <Radio id="matchStatusRadio" options={MATCH_STATUS_OPTIONS} onChange={setResponse} value={response} />
        </fieldset>

        <div className="MatchStatus__footer">
          <p className="caption-new">
            If you need additional help, please reach out to{' '}
            <a className="link" href="mailto:imperativesupport@chronus.com">
              imperativesupport@chronus.com
            </a>
          </p>
          <button className="btn-secondary" onClick={close}>
            Cancel
          </button>
          <button className="btn-primary" onClick={saveChanges} disabled={!response || !reason}>
            Save changes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default MatchStatus;
