import React from 'react'
import cx from 'classnames'
import moment from 'moment'
import * as _ from 'ramda'
import Page from 'reports/PrintPage'
import DownloadButton from 'reports/DownloadReportButton'
import {
  URL_ANIMALS,
  URL_ARCHETYPES,
  URL_DRIVERS,
  URL_HEROES,
  IMG_LOGO_BLUE
} from 'theme/assets/assets'

import Markdown from './Markdown'
import Agenda from './Agenda'
import DriverFirstPage from './DriverFirstPage'
import DriverSecondPage from './DriverSecondPage'
import DriverOverview from './DriverOverview'
import Leadership from './Leadership'
import Overview from './Overview'
import Quote from './Quote'
import Volunteering from './Volunteering'
import {
  ACTIVATE_PURPOSE
  AGENDA
  ANIMALS
  DRIVERS_OVERVIEW_QUESTIONS
  DRIVERS
  INFO
} from './content'

import './LongReport.sass'

HEROES_PREVIEW_URL = "#{URL_HEROES}/previews"
TITLE_URL = URL_ARCHETYPES

defaultOrgSettings =
  reportSettings:
    leadershipVisibility: true
    volunteeringVisibility: true
    activateVisibility: true
    heroPosterVisibility: true

getArticleFor = (text) ->
  vowels = ['a', 'e', 'i', 'o', 'u', 'y']
  firstLetter = text.slice(0, 1).toLowerCase()
  if vowels.indexOf(firstLetter) == -1 then 'a' else 'an'


export default ({report, conversation, user, noButton, t}) ->
  unless report
    return null

  number = if conversation then 1 else 2 # page numbering start
  dimensions = ['who', 'why', 'how']

  org =
    if report.orgs?.length > 1
      report.orgs.find ({code}) -> code != 'imperative'
    else
      report.orgs?[0]

  {reportSettings, purposeOverview, smallLogoUrl} = org ? defaultOrgSettings

  fullReport =
    if org?
      (org.roles.filter (r) -> r != 'Applicant').length > 0
    else
      false

  agendaSettings =
    fullReport: fullReport
    leadershipVisibility: reportSettings.leadershipVisibility
    volunteeringVisibility: reportSettings.volunteeringVisibility

  name = "#{report.firstName} #{report.lastName}"
  date = moment().format 'MMMM Do, YYYY'
  coverDate = moment().format 'MMMM D, YYYY'
  animal = report.animalHero ? ANIMALS[Math.floor(Math.random() * ANIMALS.length)]
  posterUrl = "#{HEROES_PREVIEW_URL}/#{animal}.#{report.archetype.title.toLowerCase()}.jpg"
  poster = React.createElement("div", {"className": "LongReport__posterBg", "style": (backgroundImage: "url(#{posterUrl})")})
  driversVisible = fullReport and not conversation

  footer = (number, visible) ->
    if visible
      React.createElement("span", {"className": (cx \
      "LongReport__footer"
      , right: number %% 2
      )},
        (unless number %% 2
          React.createElement("span", {"className": "LongReport__footerNumber"}, (number))
        ),
        (if conversation
          "Conversation Guide for #{name} • #{date} • Ⓒ Imperative"
        else
          "Individual Report for #{name} • #{date} • Ⓒ Imperative"
        ),
        (if number %% 2
          React.createElement("span", {"className": "LongReport__footerNumber"}, (number))
        )
      )

  driversOverview = (number) -> if driversVisible
    dimensions.map (dim) ->
      React.createElement(Page, {"key": (dim), "footer": (footer number+=1, true)},
        React.createElement(DriverOverview, {"driver": (report.pattern[dim]), "dimension": (dim)})
      )

  React.createElement("section", {"className": "LongReport"},
    (unless noButton
      React.createElement(DownloadButton, {"name": "IndividualReport_#{user?.firstName}_#{user?.lastName}"})
    ),

    (unless conversation
      React.createElement(Page, { \
        "background": (React.createElement("div", {"className": "LongReport__coverBg"})),  \
        "className": "LongReport__cover"
      },
        React.createElement("div", { \
          "className": "LongReport__archetypeTitle",  \
          "style": (backgroundImage: "url(#{TITLE_URL}/#{report.archetype.title}.png)")
        }),
        React.createElement("div", {"className": "LongReport__page LongReport__page_bottom"},
          React.createElement("h1", {"className": "LongReport__coverTitle"},
            (if fullReport
              React.createElement("span", null, "Personal Purpose Profile")
            else
              React.createElement("span", null, "Purpose Overview")
            )
          ),
          React.createElement("h2", {"className": "LongReport__coverName"}, (name)),
          React.createElement("h3", {"className": "LongReport__coverText"}, """
            Your guide to gaining meaning and unleashing potential at work.""", React.createElement("br", null), """
            Prepared on """, (coverDate)
          ),
          React.createElement("img", {"src": (IMG_LOGO_BLUE), "className": "LongReport__coverLogo", "alt": "Imperative", "height": "100"})
        )
      )
    ),

    (unless conversation
      React.createElement(Page, {"footer": (footer number, true)},
        React.createElement(Overview, null)
      )
    ),

    (unless conversation
      React.createElement(Page, {"footer": (footer number+=1, true)},
        React.createElement("div", {"className": "LongReport__titleArchetype"},
          React.createElement("div", { \
            "className": "LongReport__titleArchetypeBackground",  \
            "style": (backgroundImage: "url(#{HEROES_PREVIEW_URL}/#{animal}.#{report.archetype.title.toLowerCase()}.jpg)")
          }),
          React.createElement("div", {"className": "LongReport__titleArchetypeContainer"},
            React.createElement("div", {"className": "LongReport__titleArchetypeName"},
              React.createElement("img", {"src": "#{URL_ANIMALS}/purposeAnimalCircle__#{animal}.png", "alt": (animal)}),
              React.createElement("h2", {"className": "LongReport__subtitle"},
                (report.archetype.title)
              )
            ),
            React.createElement("h2", {"className": "LongReport__subtitle"},
              (if report.statement?
                report.statement
              else
                "#{report.imperative.prefix.my} #{report.imperative.who} #{report.imperative.why} #{report.imperative.how}."
              )
            )
          )
        ),
        React.createElement("div", {"className": "LongReport__reportContainer"},
          React.createElement("h3", {"className": "LongReport__text LongReport__text_bigger"}, """
            Your Purpose Drivers:
"""),
          (dimensions.map (dimension) ->
            React.createElement("div", {"className": "LongReport__driver LongReport__driverReset", "key": (dimension)},
              React.createElement("img", {"src": "#{URL_DRIVERS}/#{dimension}#{report.pattern[dimension]}.png", "className": "LongReport__driverIcon", "alt": (report.pattern[dimension])}),
              React.createElement("span", {"className": (cx "LongReport__subtitle LongReport__driverName", dimension)}, (report.pattern[dimension])),
              React.createElement("span", {"className": "LongReport__text"},
                (report.drivers[dimension].description)
              )
            )
          ),
          React.createElement("h3", {"className": "LongReport__text LongReport__text_bigger"}, """
            You are """, (getArticleFor report.leadershipStyle.name), " ", React.createElement("span", {"className": "LongReport__subtitle"}, (report.leadershipStyle.name)), """ driven leader.
""")
        ),

        React.createElement(Agenda, {"className": "LongReport__columnNarrow LongReport__agendaReset", "title": (AGENDA.title), "items": (AGENDA.items agendaSettings), "agendaReset": (true)})

      )
    ),

    (if fullReport and not conversation
      [
        React.createElement(Page, {"key": (0), "footer": (footer number+=1, false)},
          React.createElement("div", {"className": "LongReport__page LongReport__page_bottom"},
            React.createElement(Quote, {"author": "George Bernard Shaw", "className": "LongReport__quote_right LongReport__quote_huge"}, """
              This is the true joy in life, the being used for a purpose
              recognized by yourself as a mighty one; the being thoroughly
              worn out before you are thrown on the scrap heap; that being
              a force of Nature instead of a feverish selfish little clod of
              ailments and grievances complaining that the world will not
              devote itself to making you happy.
""")
          )
        )

        React.createElement(Page, {"key": (1), "footer": (footer number+=1, false)},
          React.createElement("div", {"className": "LongReport__page LongReport__page_left LongReport__page_bottom"},
            React.createElement("h1", {"className": "LongReport__title"}, """
              Your In-Depth""", React.createElement("br", null), """
              Purpose Results
""")
          )
        )
      ]
    ),

    (unless conversation
      React.createElement(Page, {"footer": (footer number+=1, true)},
        React.createElement("h1", {"className": "LongReport__title LongReport__title_withWhitespace"}, "Your Purpose", React.createElement("br", null), "Type"),
        React.createElement("p", {"className": "LongReport__text LongReport__text_firstBig"}, """
          Your purpose type is the starting point for you to understand what generates meaning
          for you in your work. There are three core drivers of purpose that determine whether
          we feel fulfilled in what we’re doing: who we want to impact, why we want change,
          and how we like to solve problems. The combination of these three defines your type.
"""),
        React.createElement("hr", {"className": "LongReport__line"}),
        React.createElement("h1", {"className": "LongReport__title LongReport__title_small LongReport__title_centered"}, (report.archetype.title)),
        React.createElement("div", {"className": "LongReport__equation"},
          (dimensions.map (dimension, i) ->
            [
              React.createElement("div", {"className": "LongReport__equationDriver", "key": (dimension+1)},
                React.createElement("span", {"className": "LongReport__equationLabel"}, (report.drivers[dimension].title)),
                React.createElement("img", { \
                  "src": "#{URL_DRIVERS}/#{dimension}#{report.drivers[dimension].title}.png",  \
                  "className": "LongReport__driverIcon LongReport__driverIcon_medium",  \
                  "alt": ""
                })
              )

              unless i == dimensions.length-1
                React.createElement("i", {"className": "fa fa-plus LongReport__equationIcon", "key": (dimension+2)})
            ]
          )
        ),
        React.createElement("p", {"className": "LongReport__text"},
          (report.archetype.description)
        )
      )
    ),

    React.createElement(Page, {"footer": (footer number+=1, true)},
      React.createElement("h2", {"className": "LongReport__title LongReport__title_small LongReport__title_withWhitespace"}, "Your Drivers Overview"),
      React.createElement("p", {"className": "LongReport__text"}, """
        There are three core drivers of purpose that determine whether we feel fulfilled in what
        we’re doing: who we impact, why we impact them, and how we impact them. While
        we all generate meaning from a wide range of sources, people tend towards specific
        patterns based on their own psychological profile. Below you’ll see your who, how, and
        why highlighted — these are your three Purpose drivers.
"""),
      (dimensions.map (dimension) ->
        React.createElement("div", { \
          "key": (dimension),  \
          "className": (cx "LongReport__driver LongReport__driver_bordered", dominant: report.drivers[dimension].dominant)
        },
          React.createElement("div", {"className": "LongReport__equationDriver"},
            React.createElement("img", { \
                "src": "#{URL_DRIVERS}/#{dimension}#{report.drivers[dimension].title}.png",  \
                "className": "LongReport__driverIcon LongReport__driverIcon_medium",  \
                "alt": ""
            }),
            React.createElement("span", {"className": "LongReport__equationLabel"}, (report.drivers[dimension].title))
          ),
          React.createElement("div", {"className": "LongReport__driverContent"},
            (if report.drivers[dimension].dominant
              React.createElement("h2", {"className": "LongReport__subtitle LongReport__subtitle_small"}, "DOMINANT")
            ),
            React.createElement("h2", {"className": "LongReport__subtitle LongReport__subtitle_small LongReport__subtitle_black"},
              (DRIVERS_OVERVIEW_QUESTIONS[dimension])
            ),
            React.createElement("p", {"className": "LongReport__text"},
              (report.coreBeliefs[dimension])
            )
          )
        )
      )
    ),

    (dimensions.map (dim, i) ->
      [
        React.createElement(Page, { \
          "key": (unless report.drivers[dim].dominant then i),  \
          "background": (if report.drivers[dim].dominant then React.createElement("div", {"className": (cx "LongReport__dominantBg", dim)}, React.createElement("div", null))),  \
          "footer": (if report.drivers[dim].dominant then footer number+=1, false else footer number+=1, true)
        },
          React.createElement(DriverFirstPage, {"driver": (report.drivers[dim]), "dimension": (dim)})
        )
        React.createElement(Page, { \
          "key": (unless report.drivers[dim].dominant then i+3),  \
          "background": (if report.drivers[dim].dominant then React.createElement("div", {"className": (cx "LongReport__dominantBg", dim)}, React.createElement("div", null))),  \
          "footer": (if report.drivers[dim].dominant then footer number+=1, false else footer number+=1, true)
        },
          React.createElement(DriverSecondPage, {"driver": (report.drivers[dim]), "dimension": (dim)})
        )
      ]
    ),

    (if fullReport and not conversation
      [
        React.createElement(Page, {"key": (0), "footer": (footer number+=1, false)},
          React.createElement("div", {"className": "LongReport__page LongReport__page_bottom"},
            React.createElement(Quote, {"author": "Albert Schweitzer", "className": "LongReport__quote_right LongReport__quote_huge"}, """
              The purpose of human life is to serve, and to show
              compassion and the will to help others.
""")
          )
        )

        React.createElement(Page, {"key": (1), "footer": (footer number+=1, false)},
          React.createElement("div", {"className": "LongReport__page LongReport__page_left LongReport__page_bottom"},
            React.createElement("h1", {"className": "LongReport__title"}, """
              Your Purpose in Action
""")
          )
        )
      ]
    ),

    (if false and purposeOverview?.overview?.length and not conversation
      React.createElement(Page, {"footer": (footer number+=1, true)},
        React.createElement("h1", {"className": "LongReport__title LongReport__title_withWhitespace"}, """
          Purpose at
""", React.createElement("img", {"src": (smallLogoUrl), "className": "LongReport__titleLogo", "alt": "logo"})
        ),
        (if purposeOverview?.quote?
          React.createElement("div", {"className": "LongReport__quote LongReport__quote_big LongReport__quote_noMargin"},
            React.createElement(Markdown, {"source": (purposeOverview?.quote.quote), "className": "LongReport__quoteText"}),
            React.createElement(Markdown, {"source": (purposeOverview?.quote.author), "className": "LongReport__quoteAuthor"})
          )
        ),
        React.createElement("hr", {"className": "LongReport__line LongReport__line_thin"}),
        React.createElement(Markdown, {"source": (purposeOverview?.overview)})
      )
    ),

    (if purposeOverview?.drivers? and not conversation
      React.createElement(Page, {"footer": (footer number+=1, true)},
        React.createElement("h2", {"className": "LongReport__subtitle LongReport__title_withWhitespace"}, """
          Purpose at
""", React.createElement("img", {"src": (smallLogoUrl), "className": "LongReport__subtitleLogo", "alt": "logo"})
        ),
        React.createElement("div", {"className": "LongReport__row LongReport__companyOverview"},
          (dimensions.map (dim, i) ->
            driver = purposeOverview?.drivers?[report.pattern[dim].toLowerCase()]
            if driver
              React.createElement("div", { \
                "key": (i),  \
                "className": (cx "LongReport__column_centered LongReport__columnThree")
              },
                React.createElement("img", {"className": "LongReport__driverIcon LongReport__driverIcon_medium", "src": "#{URL_DRIVERS}/#{dim}#{report.pattern[dim]}.png", "alt": (report.pattern[dim])}),
                React.createElement("h3", {"className": "LongReport__equationLabel LongReport__equationLabel_bottom"}, (report.pattern[dim])),
                React.createElement(Markdown, {"source": (driver)}),
                React.createElement("hr", {"className": "LongReport__line LongReport__line_thick #{dim}"})
              )
          )
        )
      )
    ),

    (if fullReport and (reportSettings.leadershipVisibility or conversation)
      [
        React.createElement(Page, {"key": (0), "footer": (footer number+=1, true)},
          React.createElement(Leadership, null)
        )

        React.createElement(Page, {"key": (1), "footer": (footer number+=1, true)},
          React.createElement("h2", {"className": "LongReport__subtitle LongReport__title_withWhitespace"}, "You Are ", (getArticleFor report.leadershipStyle.name), " ", (report.leadershipStyle.name), " Driven Leader"),
          React.createElement("p", {"className": "LongReport__text LongReport__text_margin-top"},
            (report.leadershipStyle.paragraph)
          ),
          React.createElement("div", {"className": "LongReport__row LongReport__row_bottom"},
            React.createElement("div", {"className": "LongReport__columnTwo LongReport__excel"},
              React.createElement("h3", {"className": "LongReport__subtitle LongReport__subtitle_black LongReport__subtitle_black LongReport__subtitle_smaller"}, """
                Where You Excel
"""),
              React.createElement("p", {"className": "LongReport__text LongReport__text_bold"},
                (report.leadershipStyle.strengths.map (val, j) ->
                  React.createElement("span", {"className": "LongReport__listItem LongReport__listItem_bullet", "key": (j)},
                    React.createElement("span", {"className": "LongReport__listItemContent"},
                      (val)
                    )
                  )
                )
              )
            ),
            React.createElement("div", {"className": "LongReport__columnTwo LongReport__struggle"},
              React.createElement("h3", {"className": "LongReport__subtitle LongReport__subtitle_white LongReport__subtitle_smaller"}, """
                Avoid Blind Spots
"""),
              React.createElement("p", {"className": "LongReport__text LongReport__text_bold"},
                (report.leadershipStyle.challenges.map (val, j) ->
                  React.createElement("span", {"className": "LongReport__listItem LongReport__listItem_bullet", "key": (j)},
                    React.createElement("span", {"className": "LongReport__listItemContent"},
                      (val)
                    )
                  )
                )
              )
            )
          )
        )
      ]
    ),

    (if fullReport and reportSettings.volunteeringVisibility and not conversation
      [
        React.createElement(Page, {"key": (2), "footer": (footer number+=1, true)},
          React.createElement(Volunteering, null)
        )

        React.createElement(Page, {"key": (3), "footer": (footer number+=1, true)},
          React.createElement("h2", {"className": "LongReport__subtitle"}, "Volunteering + Philanthropy"),
          React.createElement("p", {"className": "LongReport__text LongReport__text_bigger LongReport__text_bigger_withBottomMargin"},
            (report.archetype.volunteerBottomLine)
          ),
          (dimensions.map (dimension) ->
            React.createElement("div", {"key": (dimension), "className": "LongReport__volunteeringDriver"},
              React.createElement("div", {"className": (cx "LongReport__equationDriver LongReport__equationDriver_bordered", dimension)},
                React.createElement("img", { \
                    "src": "#{URL_DRIVERS}/#{dimension}#{report.drivers[dimension].title}.png",  \
                    "className": "LongReport__driverIcon LongReport__driverIcon_medium",  \
                    "alt": (report.drivers[dimension].title)
                }),
                React.createElement("span", {"className": "LongReport__equationLabel"}, (report.drivers[dimension].title))
              ),
              React.createElement("div", {"className": (cx "LongReport__driverContent LongReport__driverContent_bordered", dimension)},
                (report.drivers[dimension].volunteerParagraph.map (paragraph, i) ->
                  React.createElement("p", {"key": (i), "className": "LongReport__text LongReport__text_left"}, (paragraph))
                )
              )
            )
          )
        )
      ]
    ),

    (if fullReport and not conversation
      [
        React.createElement(Page, {"key": (0), "footer": (footer number+=1, true)},
          React.createElement("h1", {"className": "LongReport__title"}, "Potential Biases"),
          React.createElement(Quote, {"className": "LongReport__quote_big", "author": "Isaac Asimov"}, """
            Your assumptions are your windows on the world.
            Scrub them off every once in a while or the light won’t come in.
"""),
          React.createElement("p", {"className": "LongReport__text LongReport__text_firstBig"}, """
            Don’t hold back or hide your awesomeness. Embrace it, understand it, and most of
            all, make use of it to be at your best! Unleashing your superpowers starts with an
            active and conscious alignment of your work towards what drives purpose for you. It
            also helps to understand your potential biases, or blind spots.
"""),
          React.createElement("p", {"className": "LongReport__text"}, """
            Recognize your biases and how they might be different from your manager, colleagues,
            or team members. Consider how relationships, impact, and growth can be taken to the
            next level when working with people who have a different orientation towards purpose
            (listed in the pages that follow). Embrace your superpowers, and help others do the same!
"""),

          React.createElement("div", {"className": "LongReport__driver LongReport__driver_top"},
            React.createElement("div", {"className": "LongReport__driver LongReport__driver_top"},
              React.createElement("div", {"className": "LongReport__equationDriver LongReport__equationDriver_bordered"},
                React.createElement("img", { \
                    "src": "#{URL_DRIVERS}/who#{report.drivers.who.title}.png",  \
                    "className": "LongReport__driverIcon LongReport__driverIcon_medium",  \
                    "alt": (report.drivers.who.title)
                }),
                React.createElement("span", {"className": "LongReport__equationLabel"}, (report.drivers.who.title)),
                (if report.drivers.who.dominant
                  React.createElement("span", {"className": "LongReport__equationLabel LongReport__subtitle LongReport__subtitle_smaller"}, """
                    DOMINANT
""")
                )
              ),
              React.createElement("div", {"className": "LongReport__driverContent"},
                React.createElement("div", {"className": "LongReport__text"},
                  (report.drivers.who.potentialBiases.map (bias, i) ->
                    React.createElement("span", {"key": (i), "className": "LongReport__listItem"},
                      React.createElement("span", {"className": "LongReport__listItemContent LongReport__listItemBias LongReport__listItem_dash"},
                        (bias)
                      )
                    )
                  )
                )
              )
            )
          )
        )

        React.createElement(Page, {"key": (1), "footer": (footer number+=1, true)},
          React.createElement("h2", {"className": "LongReport__subtitle"}, "Potential Biases"),
          (['why', 'how'].map (dim) ->
            React.createElement("div", {"key": (dim), "className": "LongReport__driver LongReport__driver_top"},
              (if dim == 'how'
                React.createElement("div", {"className": "LongReport__equationDriver LongReport__equationDriver_bordered"},
                  React.createElement("img", { \
                      "src": "#{URL_DRIVERS}/#{dim}#{report.drivers[dim].title}.png",  \
                      "className": "LongReport__driverIcon LongReport__driverIcon_medium",  \
                      "alt": (report.drivers[dim].title)
                  }),
                  React.createElement("span", {"className": "LongReport__equationLabel"}, (report.drivers[dim].title)),
                  (if report.drivers[dim].dominant
                    React.createElement("span", {"className": "LongReport__equationLabel LongReport__subtitle LongReport__subtitle_smaller"}, """
                      DOMINANT
""")
                  )
                )
              ),
              React.createElement("div", {"className": "LongReport__driverContent"},
                React.createElement("div", {"className": "LongReport__text"},
                  (report.drivers[dim].potentialBiases.map (bias, i) ->
                    React.createElement("span", {"key": (i), "className": "LongReport__listItem"},
                      React.createElement("span", {"className": "LongReport__listItemContent LongReport__listItemBias LongReport__listItem_dash"},
                        (bias)
                      )
                    )
                  )
                )
              ),
              (if dim == 'why'
                React.createElement("div", {"className": "LongReport__equationDriver LongReport__equationDriver_bordered"},
                  React.createElement("img", { \
                      "src": "#{URL_DRIVERS}/#{dim}#{report.drivers[dim].title}.png",  \
                      "className": "LongReport__driverIcon LongReport__driverIcon_medium",  \
                      "alt": (report.drivers[dim].title)
                  }),
                  React.createElement("span", {"className": "LongReport__equationLabel"}, (report.drivers[dim].title)),
                  (if report.drivers[dim].dominant
                    React.createElement("span", {"className": "LongReport__equationLabel LongReport__subtitle LongReport__subtitle_smaller"}, """
                      DOMINANT
""")
                  )
                )
              )
            )
          ),
          React.createElement("div", {"className": "LongReport__biasesFooter LongReport__subtitle LongReport__subtitle_black LongReport__subtitle_smaller"}, """
            Check out the overviews of the WHO, WHY and HOW drivers on the following pages to
            research and better understand other drivers.
""")
        )
      ]
    ),

    (driversOverview number),

    (if fullReport and reportSettings.activateVisibility and not conversation
      React.createElement(Page, {"footer": (footer (if driversVisible then number+=4 else number+=1), true)},
        React.createElement("h1", {"className": "LongReport__title"}, "Activate Your Purpose"),
        React.createElement("p", {"className": "LongReport__text"},
          React.createElement("span", {"className": "LongReport__subtitle LongReport__subtitle_black"}, "Purpose is a choice. "),
          React.createElement("span", {"className": "LongReport__text"}, """
            It’s a choice to show up as your best self every day at
            work. Below are some tips for how you can unleash your inner superhero and deepen
            your practices around purpose every day.
""")
        ),
        (ACTIVATE_PURPOSE.map ({title, subtitle, items}, j) ->
          React.createElement("p", {"className": "LongReport__text", "key": (j)},
            React.createElement("span", {"className": "LongReport__subtitle LongReport__subtitle_black"}, (title), " "),
            React.createElement("span", {"className": "LongReport__text"}, (subtitle)),
            (items.map (item, i) ->
              React.createElement("span", {"className": "LongReport__listItem LongReport__listItem_square", "key": (i)},
                React.createElement("span", {"className": "LongReport__listItemContent"},
                  (item)
                )
              )
            )
          )
        ),

        React.createElement("p", {"className": "LongReport__text"},
          React.createElement("span", {"className": "LongReport__subtitle LongReport__subtitle_black"}, "Focus "),
          React.createElement("span", {"className": "LongReport__text"}, """
            on these tips tailored just for you based on your Purpose Type:
"""),
          (report.archetype.whatsNext.map (item, i) ->
            React.createElement("span", {"className": "LongReport__listItem LongReport__listItem_square", "key": (i)},
              React.createElement("span", {"className": "LongReport__listItemContent"},
                (item)
              )
            )
          )
        )
      )
    ),

    (unless conversation
      React.createElement(Page, {"footer": (footer number+=1, false)},
        React.createElement("div", {"className": "LongReport__page LongReport__page_bottom"},
          React.createElement(Quote, {"author": "John F. Kennedy", "className": "LongReport__quote_right LongReport__quote_huge"}, """
            Efforts and courage are not enough without purpose and direction.
""")
        )
      )
    ),

    (unless conversation
      React.createElement(Page, {"footer": (footer number+=1, true)},
        React.createElement("div", {"className": "LongReport__columnWide LongReport__columnWide_padding-right"},
          React.createElement("h2", {"className": "LongReport__subtitle"}, "Purpose Assessment and Profile Info"),
          React.createElement("div", {"className": "LongReport__profileInfo"},
            (INFO.paragraphs.map (paragraph, i) ->
              React.createElement("p", {"key": (i), "className": (cx "LongReport__text LongReport__text_condensed", "LongReport__text_margin-top": i==0)},
                (paragraph)
              )
            )
          )
        ),
        React.createElement("div", {"className": "LongReport__columnNarrow"},
          (dimensions.map (dimension) ->
            DRIVERS[dimension].map (driver) ->
              React.createElement("div", {"key": (dimension+driver), "className": (cx "LongReport__infoDriver", dimension)},
                React.createElement("img", {"src": "#{URL_DRIVERS}/#{dimension}#{driver}.png", "className": "LongReport__driverIcon LongReport__driverIcon_small", "alt": (driver)}),
                React.createElement("span", {"className": "LongReport__subtitle LongReport__subtitle_smaller LongReport__subtitle_black LongReport__subtitle_bold LongReport__driverName"},
                  (driver)
                )
              )
          ),

          React.createElement(Agenda, {"title": (INFO.agenda.title), "items": (INFO.agenda.items), "className": "LongReport__rights"})
        )
      )
    ),

    (if reportSettings.heroPosterVisibility and not conversation
      React.createElement(Page, { \
        "background": (poster),  \
        "className": "LongReport__poster"
      },
        React.createElement("span", {"className": "LongReport__posterLabel"}, """
          To pick out a different superhero animal visit https:\x2F\x2Fapp.imperative.com\x2Fhero
""")
      )
    )

  )
