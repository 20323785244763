import React, { useCallback } from 'react';

import './TranslationError.sass';


const TranslationError = () => {
  const reload = useCallback(() => {
    localStorage.removeItem('imperative-language-data');
    localStorage.removeItem('imperative-language-options');
    location.reload();
  });

  return (
    <div className="TranslationError" role="banner">
      <div className="TranslationError__container">
        <div>
          <p>
            We have some issues with loading translations on this page.
            Please, wait a minute and click button to reload it.
          </p>
          <p>If that doesn&apos;t help contact support: <a href="mailto:imperativesupport@chronus.com" className="TranslationError__mail">imperativesupport@chronus.com</a>.</p>
        </div>
        <button className="btn-secondary" onClick={reload}>Reload</button>
      </div>
    </div>
  )
}

export default TranslationError;